.vertical-timeline span {
    color: white;
    font-family: 'Courier Prime', monospace;
}

.vertical-timeline p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element p {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h3 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element h4 {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element span {
    color: rgb(39, 40, 34);
}

.vertical-timeline-element .vertical-timeline-element-date {
    color: white;
}

.svg-inline--fa {
    color: white;
}

@media screen and (max-width: 1170px) {
    .vertical-timeline-element .vertical-timeline-element-date {
        color: rgb(39, 40, 34);
    }
}
@media (max-width: 700px) {
    .history-title{
        margin-bottom: 50px; 
       
    }
}