.projects-container {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    text-align: left;
}
  
.buttons-project-container{
    display: flex;
   
    gap: 15px;
}
.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}
  
.projects-container .projects-grid .project {
    text-align: left;
}
  


.project-button{
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);  
}

.zoom {
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
}
  
.zoom:hover {
    transform: scale(1.05);
}

@media screen {
    @media (max-width: 700px) {
        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }
  
        .projects-container .projects-grid {
            display: block;
        }
      
        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }
    }
}

.video-container {
    position: relative;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
@media (max-width: 700px) {
      
.buttons-project-container{
    margin-bottom:50px ;
}
    .projects-title{

       margin-bottom: 40px; 
       margin-top: 40px; 
    }
}
@media (min-width: 701px) and (max-width: 1024px) {
    .buttons-project-container {
        justify-content: space-between; /* Adjust alignment for medium screens */
        gap: 10px; /* Reduce gap for medium screens */
    }

    .projects-container {
        padding-left: 7%; /* Adjust padding for medium screens */
        padding-right: 7%;
    }

    .projects-container .projects-grid {
        grid-template-columns: repeat(2, 1fr); /* Maintain two columns */
        grid-gap: 30px; /* Adjust grid gap */
    }
    
    .projects-container .projects-grid .project {
        padding-bottom: 10%; /* Adjust padding for projects */
    }

    .projects-title {
        margin-bottom: 30px; /* Adjust margin for title */
        margin-top: 30px;
    }
}
